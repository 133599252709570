<template>

    <div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow:scroll" ref="scroller" @scroll="scrollingContainerShadow" >

        <ContractTemplates :title="filter.label" :contracts="contracts" v-if="contracts.length"/>

        <EmptyApp :app="$store.state.theApp" text="You don't have any Templates"  v-else />


    </div>

</template>

<script>
    import {scrollingContainerShadow} from 'bh-mod'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import ContractTemplates from '@/components/forms/ContractTemplates'

    export default {
        components:{
            EmptyApp,ContractTemplates
        },
        data() {
            return {
                timeNow:Date.now(),
                mailOpen:'',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
				searchMode:false,
				selectContactView: false,
				templateId: '',
				customer : ''
            }
        },
        watch:{
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
            filter(){
                return this.$store.state.appData.filter
            },
			contracts(){

				return Object.values(this.$store.state.appData.templates).filter(contract => {
                    if (this.filter.value === 'all') return true
                    return contract.type === this.filter.value
                }).sort( (a,b) => {
                    a.updatedAt = new Date(a.updatedAt).getTime()
                    b.updatedAt = new Date(b.updatedAt).getTime()
                    return b.updatedAt - a.updatedAt
                })
			},

            instance(){
                return this.$store.state.instance
            },
            loadingPage:{
                get(){
                    return this.$store.state.loading
                },
                set(val){
                    this.$store.commit('LOAD',val)
                }
            }
        },
        methods:{
            scrollingContainerShadow,
			goToContract(item){
				console.log('GOING TO ITEM', item)
				this.$router.push('/contract/'+item.id)
			},

			use(id){
				console.log('use .. templateId', id);
				this.templateId = id;
				this.selectContactView = true;
			},

			selectContact(){
				console.log('selectContact');

				let formData = new FormData();
				formData.set("templateId", this.templateId);
				formData.set("customer", this.customer);

				this.$api
				.post(`/contract-templates/${this.instance.id}/${this.templateId}/`, formData)
				.then(({ data }) => {
					// this.onClose({ type: "add", data });
				}).catch((err) => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});


				// this.selectContactView = false;
			},

			closeSelectContactView(){
				this.selectContactView = false;
				this.templateId = '';
			}


        },
        created(){
            this.$store.commit('PAGE_BUTTONS', [
                {
                    label:'ADD CONTRACT TEMPLATE',
                    ant:'plus',
                    callback: () => this.$router.push('/template')
                },
            ])
        },
        mounted(){

        }
    }
</script>

<style lang="scss" scoped>

    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }


</style>
