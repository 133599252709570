<template>
    <div>
        <a-modal :title="renameModal.dup? 'Enter name of the duplicate' : renameModal.move ? 'Move template' : 'Rename template'" :okText="renameModal.dup ? 'Duplicate' : renameModal.move ? 'Move' : 'Rename'" :visible="renameModal.show" @ok="renameContract" @cancel="() => {renameModal.show = false; renameModal.move = false;}">
            <template v-if="renameModal.move">
                <a-form-model-item label="Contract Type" prop="type">
                    <a-select v-model="renameModal.name" class="w-full">
                        <a-select-option :value="t.value" v-for="t in contractTypes" :key="t.value">{{t.label}}</a-select-option>
                    </a-select>
                </a-form-model-item>
            </template>
            <a-input v-else v-model="renameModal.name" />
        </a-modal>

		<a-modal
			v-model="exportModal.visible"
			width="500px"
			:footer="null"
			@cancel="closeExportModal"
			:centered="true"
		>
			<BHLoading :show="loading" />
			<div class="mb-2">
				<h5 class="mb-4" v-text="'Export to Existing Project'" />

				<p class="mb-2" style="color: #40454c; font-size: 14px">
					Select a Project<span style="color: var(--danger)"> *</span>
				</p>
				<a-select
					size="large"
					v-model="exportModal.instance"
					class="w-full"
					placeholder="Select a Project"
					show-search
					:filter-option="filterOption"
				>
					<a-select-option v-for="(inst,instI) in instances" :value="inst.id" :key="inst+instI">{{inst.name}}</a-select-option>
				</a-select>
			</div>
			<div class="dF jE mt-5">
				<a-button class="cancel-button" @click="closeExportModal">CANCEL</a-button>
				<a-button class="ml-3" type="primary" @click="confirmExport()">
					EXPORT
				</a-button>
			</div>
		</a-modal>

        <div class="dF jSB">

            <h4 class="pb-2" >{{title}}</h4>
            <MoreActions v-if="selectedRowKeys.length" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>

        </div>
        <!-- <Table  :row-selection="rowSelection" :columns="columns" dataSource="data"/> -->
        <a-table :rowKey="e => e.id" :row-selection="rowSelection" :columns="columns" :data-source="contractsTransformed" class="white-table" >
            <template slot="description" slot-scope="record" >
                <span v-if="record.description"></span>
                <span v-else class="text-med-gray">
                    No Description...
                </span>

            </template>
            <template slot="name" slot-scope="record" >
                <router-link :to="`/template/${record.id}/prepare`" class="fw-500">
                    <Icons type="contract-file" style="width:10px;color:var(--red);" class="mr-2" />
                    {{record.name}}
                </router-link>
            </template>
            <template slot="pagesLength" slot-scope="record" >
                {{getPagesLength(record)}}
            </template>
            <template slot="updatedAt" slot-scope="record" >
                {{formatDate(record.updatedAt)}}
            </template>
            <template slot="type" slot-scope="record" >
                {{lookup[record.type]? lookup[record.type] : record.type[0].toUpperCase() + record.type.substr(1)}}
            </template>
            <template slot="action" slot-scope="record" >
                <MoreActions :items="moreActions" :val="record" @action="action"/>
            </template>
        </a-table>
    </div>

</template>

<script>
// import Table from 'bh-mod/components/common/Table'
import Icons from '@/components/common/BHIcons'
import MoreActions from 'bh-mod/components/common/MoreActions'
import {formatDate} from 'bh-mod'
import BHLoading from 'bh-mod/components/common/Loading'

export default {
    props:{
        contracts:{
            type:Array,
            default:() => []
        },
        title:{
            type:String,
            default:'Contract Templates'
        },
    },

    components:{Icons,MoreActions,BHLoading},
    data(){
        return {
            updatedTemplates:{},
            renameModal:{
                dup:false,
                move:false,
                show:false,
                id:'',
                name:''
            },
			exportModal:{
				visible:false,
				template:{},
				instance:''
			},
            lookup:{
                lease:'Main Lease Agreement',
				purchase:'Main Purchase Agreement',
				amendPurchase:'Amendment/Document Agreement',
				amendLease:'Amendment Lease Agreement',
				reservation:'Reservation Agreement',
				other:'Supporting Document Agreement'
            },
            types:[
                {
                    label:'Main Purchase Template',
                    value:'purchase'
                },
                {
                    label:'Main Lease Template',
                    value:'lease'
                },
                {
                    label:'Amendment/Document Template',
                    value:'amendPurchase'
                },
                {
                    label:'Amendment Lease Template',
                    value:'amendLease'
                },
				{
					label: 'Reservation Agreement Template',
					value: 'reservation'
				},
                {
                    label:'Supporting Document Template',
                    value:'other'
                },
            ],
            loadingPage:false,
			loading:false,
            bulkActions:[
                {
                    label:'Delete Selected Contracts',
                    value:'delete'
                }
            ],
            selectedRowKeys:[],
            selectedRows:[],
            moreActions:[
                {
                    label:'Preview',
                    value:'preview',
                    icon:'fe-eye',
                },
                {
                    label:'Rename',
                    value:'rename',
                    icon:'fa-edit',
                },
                {
                    label:'Edit',
                    value:'edit',
                    icon:'fa-pencil',
                },
                {
                    label:'Duplicate',
                    value:'duplicate',
                    icon:'fa-copy',
                },
                {
                    label:'Move',
                    value:'move',
                    icon:'fe-log-out',
                },
				{
					label:'Export to Project',
					value:'export',
					icon:'fe-upload',
				},
                {
                    label:'Delete',
                    value:'delete',
                    icon:'fe-trash'
                },
            ],
            columns: [
                {
                    title: 'Contract Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Pages',
                    key: 'pagesLength',
                    scopedSlots: { customRender: 'pagesLength' },
                },
                // {
                //     title: 'Description',
                //     key: 'description',
                //     scopedSlots: { customRender: 'description' },
                // },
                {
                    title: 'Contract Type',
                    key: 'cType',
                    scopedSlots: { customRender: 'type' },
                },
                {
                    title: 'Last Modified',
                    key: 'updatedAt',
                    scopedSlots: { customRender: 'updatedAt' },
                },
                {
                    title: 'Action',
                    key: 'action',
                    width:'10%',
                    scopedSlots: { customRender: 'action' },
                },
            ]
        }
    },
    computed: {
		instances(){
			return this.$store.state.instances.filter(x => x.id != this.instance.id).sort((a,b) => {
				let nameA = a.name.toUpperCase()
				let nameB = b.name.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			})
		},
		instance(){
			return this.$store.state.instance
		},
        contractsTransformed(){
            return this.contracts.map(x => x)
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: true,
                // selections: [
                    // {
                    //     key: 'all-data',
                    //     text: 'Select All',
                    //     onSelect: () => {
                    //         this.selectedRowKeys = [...Array(46).keys()]; // 0...45
                    //     },
                    // },
                    // {
                    //     key: 'odd',
                    //     text: 'Select Odd Row',
                    //     onSelect: changableRowKeys => {
                    //     let newSelectedRowKeys = [];
                    //     newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                    //         if (index % 2 !== 0) {
                    //         return false;
                    //         }
                    //         return true;
                    //     });
                    //     this.selectedRowKeys = newSelectedRowKeys;
                    //     },
                    // },
                    // {
                    //     key: 'even',
                    //     text: 'Select Even Row',
                    //     onSelect: changableRowKeys => {
                    //     let newSelectedRowKeys = [];
                    //     newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                    //         if (index % 2 !== 0) {
                    //         return true;
                    //         }
                    //         return false;
                    //     });
                    //     this.selectedRowKeys = newSelectedRowKeys;
                    //     },
                    // },
                // ],
                onSelection: this.onSelection,

                getCheckboxProps: record => ({
                    props: {
                        disabled: record.name === 'Disabled User', // Column configuration not to be checked
                        name: record.name,
                    },
            }),
            }
        },

		contractTypes() {
			let types = this.types;
			if (this.reservationAgreementExist || !this.$store.getters.isPremiumPackage) {
				types = types.filter(t => t.value !== 'reservation')
			}
			return types
		},

		reservationAgreementExist() {
			const contracts = Object.values(this.$store.state.appData.templates);

			return !!(contracts.find(c => c.type === 'reservation'))
		}
    },
    methods:{
		closeExportModal(){
			this.exportModal = {
				visible:false,
				template:{},
				instance:''
			}
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},
		async confirmExport(){
			if (this.exportModal.instance == ''){
				return this.$message.error('Please select an instance')
			}
			this.loading = true
			this.$api.post(`/contract-templates/:instance/${this.exportModal.template.id}/copytemplate?instance=${this.exportModal.instance}`, {name:this.exportModal.template.name + ' COPY'}).then( ({data}) =>{
				this.loading = false;
				this.$message.success(`Exported Contract Template: ${this.exportModal.template.name}`)
				this.closeExportModal();
			})
			.catch( () => {
				this.$message.error('Failed to copy template')
				this.loading = false
			})
			.finally ( () => {
				this.loading = false
				this.closeExportModal()
			})
		},
        renameContract(){
            if (!this.renameModal.name.trim()) {
                return this.$message.error('Please enter a name')
            }
            this.$store.commit('LOAD',true)
            if (this.renameModal.dup){
                this.$api.post(`/contract-templates/:instance/${this.renameModal.id}/copytemplate`, {name:this.renameModal.name}).then( ({data}) =>{
                    this.$store.commit('SET_PROP', {where:['templates',data.id],what:data})
                })
                .catch( () => {
                    this.$message.error('Failed to copy template')
                })
                .finally ( () => {
                    this.$store.commit('LOAD',false)
                    this.renameModal.show = false
                })
            } else if (this.renameModal.move){
                let sendObj = JSON.parse(JSON.stringify(this.contracts.find(x => x.id == this.renameModal.id)))
                sendObj.type = this.renameModal.name
                this.$api.put(`/contract-templates/:instance/${this.renameModal.id}`, sendObj).then( ({data}) =>{
                    this.$store.commit('SET_PROP', {where:['templates',data.id],what:data})
                })
                .catch( () => {
                    this.$message.error('Failed to move template')
                })
                .finally ( () => {
                    this.$store.commit('LOAD',false)
                    this.renameModal.show = false
                })
            } else {
                this.$api.put(`/contract-templates/:instance/${this.renameModal.id}`, {name:this.renameModal.name}).then( ({data}) =>{
                    this.$store.commit('SET_PROP', {where:['templates',data.id],what:data})
                })
                .catch( () => {
                    this.$message.error('Failed to rename template')
                })
                .finally ( () => {
                    this.$store.commit('LOAD',false)
                    this.renameModal.show = false
                })
            }
        },
        formatDate,
        async bulkActioned({key,keyPath,val}){
            let self = this
            if (key === 'delete'){
                if (this.$p < 40) return this.$message.error('You do not have permission to delete contracts')
                this.$confirm({
                    title: `Are you sure you want to delete the selected Contract Templates (${self.selectedRowKeys.length} in Total) ?`,
                    okText: 'YES',
                    okType: 'danger',
                    cancelText: 'NO',
                    onOk() {
                        self.$api.put(`/contract-templates/${self.$store.state.instance.id}/delete`, self.selectedRowKeys).then( ({data}) => {
                            self.selectedRowKeys.forEach(id => {
                                self.$store.commit('SET_PROP', {where:['templates',id],del:true})
                            })
                            self.selectedRowKeys = []
                        }).catch( err => {
                            console.error('errror', err)
                            return self.$message.error('There was an error deleting the files')
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                    class: 'test',
                })
            }
        },
        async action(e){
            if (e.key === 'rename'){
                this.renameModal.show = true
                this.renameModal.dup = false
                this.renameModal.move = false
                this.renameModal.id = e.val.id
                this.renameModal.name = e.val.name
            } else if (e.key === 'move'){
                this.renameModal.show = true
                this.renameModal.dup = false
                this.renameModal.move = true
                this.renameModal.id = e.val.id
                this.renameModal.name = this.contracts.find(x => x.id === e.val.id).type
            } else if (e.key === 'duplicate'){
                this.renameModal.show = true
                this.renameModal.dup = true
                this.renameModal.move = false
                this.renameModal.id = e.val.id
                this.renameModal.name = e.val.name + ' (Copy)'
            } else if (e.key === 'preview'){
                this.$store.commit('LOAD')

				try {
					let {data} = await this.$api.post(`/contract-templates/:instance/${e.val.id}/preview`)
					let {data:url} = await this.$api.get(`/contract-templates/:instance/getfile?key=${data}`);

					this.$store.commit('SHOW_PREVIEW', url)
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error occurred while previewing contract. Please try again!`))
					}
				}

                this.$store.commit('LOAD',false)

            } else if (e.key === 'edit'){
                this.$router.push(`/template/${e.val.id}/prepare`)
            } else if (e.key === 'export'){
				console.log('Export Contract to Project...', e.val);
				this.exportModal = {
					visible: true,
					template: e.val,
					instance: ''
				}
			} else if (e.key === 'delete'){
                if (this.$p < 40) return this.$message.error('You do not have permission to delete contracts')
                let self = this
                this.$confirm({
                    title: 'Are you sure you want to delete this Contract Template?',
                    okText: 'YES',
                    okType: 'danger',
                    cancelText: 'NO',
                    onOk() {
                        self.$api.delete(`/contract-templates/${self.$store.state.instance.id}/${e.val.id}`).then( ({data}) => {
                            self.$store.commit('SET_PROP', {where:['templates',e.val.id],del:true})
                        }).catch( err => {
                            console.error('errror', err)
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                    class: 'test',
                })
            }
        },
        getPagesLength(c){
            let pages = c.pages
            return pages.length
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
    },
    watch:{
        'renameModal.show'(val){
            if (!val){
                this.renameModal.dup = false
                this.renameModal.move = false
                this.renameModal.id = ''
                this.renameModal.name = ''
            }
        }
    },
	mounted(){
		this.$api.get('/instances').then(({data}) => {
			data = data.filter(x => x.role && x.role.name === 'bh_admin' && x.instance.productType == this.instance.productType).map(x => {
				return {
					id: x.instance.id,
					name: x.instance.name
				}
			})
			this.$store.commit('SET_INSTANCES', data)
		}).catch((err) => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
        })
	}
}
</script>

<style>

</style>
